@import 'variables.scss';

.loading-section {
  background-color: $background;
  height: 600px;

  @include media-breakpoint-down(sm) {
    height: 400px;
  }
}

.loader:empty {
  position: absolute;
  top: 40%;
  left: 45%;
  width: 4em;
  height: 4em;
  border: 0.4em solid $primary;
  border-left: 0.4em solid $whitish;
  border-radius: 50%;
  animation: load8 1.8s infinite linear;

  @include media-breakpoint-down(sm) {
    top: 60%;
    left: 40%;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
