@import 'variables.scss';

.top-navbar {
  background-color: $background;
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  border-bottom: 1px solid $grayish;

  h2 {
    width: 80%;
    text-align: center;
  }
}

.side-bar-menu {
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

#colorlib-aside {
  padding-top: 3em;
  padding-bottom: 40px;
  padding-left: 3em;
  padding-right: 3em;
  text-align: center;
  width: 25%;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-y: hidden;
  z-index: 1001;
  background: repeating-linear-gradient(-120deg, rgb(224, 224, 224) 5px, rgb(255, 255, 255) 8px, rgb(214, 213, 213) 5px, rgb(241, 241, 241) 6px);

  @include transition(0.5s);

  @include media-breakpoint-up(lg) {
    width: 25%;
  }

  @include media-breakpoint-down(md) {
    width: 270px;

    @include translateX(-270px);

    padding-top: 2em;
  }

  #colorlib-logo {
    margin-bottom: 2.5em;
    display: block;
    width: 100%;
    font-weight: 900;
    font-size: 34px;

    @include media-breakpoint-down(md) {
      margin-bottom: 20px;
    }

    img {
      height: 100px;
      width: 100px;
    }
  }

  #colorlib-main-menu {
    ul {
      margin: 0;
      padding: 0;

      li {
        margin: 0 auto 15px auto;
        padding: 0;
        width: 100px;
        list-style: none;
        font-size: 16px;
        font-weight: 700;

        a {
          color: rgba(0, 0, 0, 0.9);
          text-decoration: none;
          position: relative;
          padding: 10px 0;
          font-family: $font-primary;

          @include transition(0.3s);

          &:hover {
            text-decoration: none;
            color: $primary;
            font-weight: 900;
            transition: border-bottom 0.1s ease-in-out;
          }

          &:active {
            color: $primary;
          }
        }

        &:hover {
          a {
            color: $primary;
          }
        }
      }
    }
  }

  #resume-button {
    .btn {
      border: 1px solid $primary;
      background-color: rgba(255, 255, 255, 0);
      color: $primary;
      font-family: $font-primary;

      &:hover {
        background-color: $primary;
        color: white;
      }
    }
  }

  #hire-button {
    padding-top: 50px;
    font-family: $font-primary;
  }
}

.nav-link--active {
  border-bottom: 3px solid $primary;
  transition: border-bottom 0.5s ease-in-out;
}
