
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/variables';

@font-face {
  font-family: 'hkgrotesk-medium';
  src: url("../fonts/hkgrotesk-medium.otf") format("opentype");
  font-style: normal;
}

@font-face {
  font-family: 'hkgrotesk-bold';
  src: url("../fonts/hkgrotesk-bold.otf") format("opentype");
  font-style: normal;
}

$font-secondary: "hkgrotesk-bold", sans-serif;
$font-primary: "hkgrotesk-medium", sans-serif;

$white: #fff;
$black: #000;
$darken: #3a4348;
$background:  #132238; //old - 001a33;
$background-2: #001a3367; // old - 001a3367;
$header-bg: #132238;

$primary: #ff4800;
$primary-light: #fdf8f6;
$secondary: #5dd28e;
$grayish: #999;
$whitish: #ddd;

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin transition($transition) {
  -moz-transition: all $transition ease;
  -o-transition: all $transition ease;
  -webkit-transition: all $transition ease;
  -ms-transition: all $transition ease;
  transition: all $transition ease;
}

@mixin translateX($translatex) {
  -moz-transform: translateX($translatex);
  -webkit-transform: translateX($translatex);
  -ms-transform: translateX($translatex);
  -o-transform: translateX($translatex);
  transform: translateX($translatex);
}
