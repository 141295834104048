@import 'variables.scss';

.project-col {
  border-color: $darken;
  padding: 50px 0;
  border-style: solid;
  border-width: 0 0.5px 0.5px 0;

  &:nth-child(2n+2) {
    border-right: none;
  }

  &:hover {
    background-color: $header-bg;
  }

  @include media-breakpoint-down(sm) {
    border-width: 0 0 0.5px 0;
  }
}

.blog-entry {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ul {
    list-style-type: none;
    padding: 0 0;
  }

  .project-title {
    font-weight: 700;
    font-size: 28px;

    a,
    p {
      color: white;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .project-private-section {
    display: inline-block;
    color: white;

    div {
      color: white;
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      border-radius: 6px;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: #284369;

      p {
        margin: 0;
        padding-left: 8px;
      }
    }
  }

  .detail-overlay {
    display: none;
    background-color: rgba(34, 34, 34, 0.226);
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;

    li {
      display: inline-block;
      font-size: 30px;
      margin-left: 1rem;

      a {
        padding: 0.6rem 0.8rem;
        border-radius: 50%;
        background-color: white;
        color: $primary;

        &:hover {
          background-color: $background;
        }
      }
    }
  }

  .img {
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 300px;
    justify-content: center;
    width: 90%;
    position: relative;

    .project-pic {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 90%;
        height: 90%;

        @include media-breakpoint-down(sm) {
          height: 85%;
          margin: 20px 0;
        }
      }
    }

    @include border-radius(3px);

    @include media-breakpoint-down(sm) {
      height: 230px;
    }

    &:hover {
      .detail-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-transition: background-color 2s ease-out;
        -moz-transition: background-color 2s ease-out;
        -o-transition: background-color 2s ease-out;
        transition: background-color 2s ease-out;
      }
    }
  }

  .text {
    position: relative;
    z-index: 0;

    .category {
      text-transform: capitalize;
      font-size: 14px;
      letter-spacing: 1px;
      margin-bottom: 0.6rem;
      font-family: $font-primary;

      span {
        display: inline-block;
        text-align: center;
        padding: 6px 10px;
        margin: 3px 2px;
        border-radius: 6px;
        background-color: #112a4d;
        color: #aaa;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

        @include media-breakpoint-down(sm) {
          padding: 4px 8px;
        }
      }
    }

    .project-desc {
      color: $whitish;
      font-size: 16px;
      font-family: $font-primary;
    }

    .meta-wrap {
      width: 100%;
      display: block;

      .half {
        width: 100%;
      }

      .meta {
        span {
          margin: 0 5px;
          color: lighten($black, 70%);

          i {
            margin-right: 10px;
          }
        }
      }

      .btn {
        // font-weight: 300 !important;
        text-transform: uppercase;
        font-size: 13px;
        letter-spacing: 2px;
      }
    }
  }
}