@import 'variables.scss';

.ftco-footer {
  font-size: 14px;
  background: #132238;
  padding: 6em 0;

  .ftco-footer-logo {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }

  .ftco-footer-widget {
    h2 {
      color: $white;
      margin-bottom: 40px;
      font-size: 17px;
      font-weight: 700;
      letter-spacing: 1px;
    }

    .btn-primary {
      background: $white !important;
      border: 2px solid $white !important;

      &:hover {
        background: $white;
        border: 2px solid $white !important;
      }
    }
  }

  p {
    color: rgba($white, 0.5);
  }

  a {
    color: rgba($white, 0.5);

    &:hover {
      color: $white;
    }
  }

  .ftco-heading-2 {
    font-size: 17px;
    font-weight: 400;
    color: $black;
  }

  .categories {
    li {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding: 10px 0;

      a {
        color: rgba(255, 255, 255, 0.5) !important;

        span {
          color: rgba(255, 255, 255, 0.8) !important;
        }
      }
    }
  }

  .copyright {
    color: rgba($white, 0.5);
    width: 100%;

    hr {
      background-color: #555;
    }

    p {
      text-transform: uppercase;
      letter-spacing: 1px;
      margin: 0 auto;
    }

    .social-icons {
      ul {
        margin-top: 1.5rem;
        list-style-type: none;
        padding: 0;

        li {
          display: inline-block;
          padding: 10px;
        }
      }
    }
  }
}
