
@import './variables.scss';

.about-section {
  padding: 120px 40px 60px 40px;
  height: 100vh;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding: 100px 0 0;
  }
}

.about-author {
  height: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  h1 {
    color: white;
    font-weight: 900;
    padding-bottom: 1.2rem;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }
  }

  h4 {
    color: white;
    font-family: $font-primary;
    font-size: 1.2rem;
    font-weight: 400;
    padding-bottom: 1rem;

    span {
      font-size: 1.5rem;
      padding-left: 5px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      padding-bottom: 1rem;
    }
  }

  .title {
    color: $primary;
    font-family: $font-secondary;
    font-size: 1.5rem;
    width: 60%;
    display: inline;
    margin: 0 auto;
    letter-spacing: 2px;
    padding-bottom: 8px;

    @include media-breakpoint-down(sm) {
      font-size: 1rem;
      width: 90%;
    }
  }

  .info {
    font-family: $font-primary;
    font-size: 1.3rem;
    font-weight: 100;
    padding-top: 60px;
    width: 60%;
    margin: 0 auto;
    color: $whitish;

    @include media-breakpoint-down(sm) {
      font-size: 1.1rem;
      width: 95%;
    }
  }

  .social-icons {
    padding-top: 100px;
    display: block;
    width: 100%;

    @include media-breakpoint-down(sm) {
      padding-top: 50px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        display: inline-block;
        padding: 0 16px;

        a {
          font-size: 30px;
          color: #ddd;

          &:hover {
            color: $primary;
          }

          @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
          }
        }

        @include media-breakpoint-down(sm) {
          padding: 0 8px;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    height: 500px;
  }
}
