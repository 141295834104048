@import './scss/variables.scss';

#colorlib-main {
  background-color: $background;
  width: 100%;
  float: right;

  @include transition(0.5s);

  @include media-breakpoint-up(lg) {
    width: 75%;
  }
}

body {
  font-family: $font-primary;
  background: $white;
  font-size: 15px;
  line-height: 1.8;
  font-weight: 500;
  color: lighten($black, 60%);

  &.offcanvas {
    overflow-x: hidden;

    #colorlib-aside {
      @include translateX(0);

      width: 270px;
      z-index: 999;
      position: fixed;
    }

    #colorlib-main,
    .colorlib-nav-toggle {
      top: 0;

      @include translateX(270px);
    }
  }
}

/* -----------------------------------------------------------
            scrollbar
------------------------------------------------------------ */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(0, 0, 0);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: $primary;
  border-radius: 30px;
}

a {
  transition: 0.3s all ease;
  color: $primary;

  &:hover,
  &:focus {
    text-decoration: none;
    color: $primary;
    outline: none !important;
  }
}

p {
  font-family: $font-primary;
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
  font-weight: 400;
  font-family: $font-secondary;
}

#root {
  width: 100%;
  overflow: hidden;
  position: relative;
}

//Headings
.heading-title {
  color: white;
  letter-spacing: 1px;
  font-weight: 900;
  padding: 60px 0;
  text-align: center;
  background-color: $header-bg;

  @include media-breakpoint-down(md) {
    padding-top: 100px;
  }
}

//BUTTON
.btn {
  cursor: pointer;

  @include border-radius(5px);

  box-shadow: none !important;

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  &.btn-primary {
    background: $primary;
    border: 1px solid $primary;
    color: $white;

    &:hover {
      border: 1px solid $primary;
      background: transparent;
      color: $primary;
    }

    &.btn-outline-primary {
      border: 1px solid $primary;
      background: transparent;
      color: $primary;

      &:hover {
        border: 1px solid transparent;
        background: $primary;
        color: $white;
      }
    }
  }

  &.btn-white {
    background: $white;
    border: 1px solid $white;
    color: $black;

    &:hover,
    &:focus,
    &:active {
      border: 1px solid $white;
      color: $primary;
    }

    &.btn-outline-white {
      border-color: rgba($white, 0.8);
      background: none;

      @include border-radius(30px);

      border-width: 1px;
      color: $white;

      &:hover,
      &:focus,
      &:active {
        background: $white;
        border-color: $white;
        color: $primary;
      }
    }
  }
}

.ftco-animate {
  opacity: 0;
  visibility: hidden;
}

/* The typing effect */
@keyframes typing {
  from { width: 0; }
  to { width: 60%; }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to { border-color: transparent; }
  60% { border-color: $primary; }
}

.ftco-section {
  position: relative;
}

.ftco-section .container {
  padding: 2em;

  @include media-breakpoint-down(md) {
    padding-top: 1.5em;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba($primary, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 30px rgba($primary, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0.4);
    box-shadow: 0 0 0 0 rgba($primary, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 30px rgba($primary, 0);
    box-shadow: 0 0 0 30px rgba($primary, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba($primary, 0);
    box-shadow: 0 0 0 0 rgba($primary, 0);
  }
}
