@import 'variables.scss';

.blog-item {
  background-color: $header-bg;
  margin-bottom: 30px;
  border-radius: 10px;

  a {
    color: $white;
    font-size: 1.5rem;

    &:hover {
      color: white;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }

  .blog-image {
    height: 300px;
    width: 45%;
    border-radius: 10px 0 0 10px;

    @include media-breakpoint-down(md) {
      border-radius: 10px 10px 0 0;
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      height: 200px;
      width: 100%;
    }
  }

  .blog-info {
    padding: 10px 30px;

    .blog-desc {
      color: $whitish;
      text-align: justify;
      font-family: $font-primary;
      font-size: 0.9rem;
      padding-top: 10px;

      @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
      }
    }

    .blog-meta {
      p {
        color: $grayish;
        font-weight: lighter;
        font-size: 0.8rem;
        padding-top: 20px;

        @include media-breakpoint-down(sm) {
          font-size: 0.6rem;
        }
      }
    }
  }
}

.link-btn {
  border-radius: 50%;
  padding: 5px 16px;
}

.blog-divider {
  width: 100%;
  border-bottom: 1px solid #555;
  margin-bottom: 50px;
}
